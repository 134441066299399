var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"grid-list-md":"","pa-0":"","relative":""}},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs12":"","sm12":""}},[_c('v-card',{staticClass:"primary--border",attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"title"},[_vm._v(" Student Bill Due Report "),_c('v-spacer'),(_vm.form.items.data.length && _vm.grade)?_c('v-download-column-select',{attrs:{"downloadColumns":_vm.dynamicColumns},on:{"onDownload":_vm.downloadRecord}},[_vm._v(" DOWNLOAD STUDENT RECORD ")]):_vm._e(),_vm._v("       "),(_vm.form.items.data.length)?_c('v-btn',{attrs:{"disabled":_vm.selectedStudents.length < 1,"color":"primary","small":"","outlined":""},on:{"click":function($event){_vm.sendSMSModal = true}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("message")]),_vm._v(" Send SMS ("+_vm._s(_vm.selectedStudents.length)+")")],1):_vm._e()],1),_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"title pb-0"},[_c('v-flex',{attrs:{"xs2":"","sm2":""}},[_c('v-calendar-field',{attrs:{"id-val":"fdate","label":"From Date"},model:{value:(_vm.fdate),callback:function ($$v) {_vm.fdate=$$v},expression:"fdate"}})],1),_c('v-flex',{attrs:{"xs2":"","sm2":""}},[_c('v-calendar-field',{attrs:{"id-val":"tdate","label":"To Date"},model:{value:(_vm.tdate),callback:function ($$v) {_vm.tdate=$$v},expression:"tdate"}})],1),_c('v-flex',{attrs:{"xs3":"","sm3":""}},[_c('v-select',{staticClass:"pa-0",attrs:{"disabled":_vm.gradeLoading,"loading":_vm.gradeLoading,"items":[{ value: 'all', text: 'All' }, ..._vm.grades],"label":"Grade","outlined":"","dense":""},model:{value:(_vm.grade),callback:function ($$v) {_vm.grade=$$v},expression:"grade"}})],1),_c('v-flex',{attrs:{"xs3":"","sm3":""}},[_c('v-select',{staticClass:"pa-0",attrs:{"disabled":!_vm.grade,"items":[
                  { value: 'all', text: 'All' },
                  { value: 'left', text: 'Left Student' },
                  { value: 'current', text: 'Current Student' },
                ],"label":"Filter Type","outlined":"","dense":""},model:{value:(_vm.report_type),callback:function ($$v) {_vm.report_type=$$v},expression:"report_type"}})],1),_c('v-flex',{attrs:{"xs2":"","sm2":""}},[_c('search-button',{attrs:{"disabled":!_vm.batchId && !_vm.grade,"permission":"billing-report-collection"},on:{"action":function($event){return _vm.get()}}},[_vm._v(" Search ")])],1)],1)],1),_c('br'),(_vm.form.items.data.length > 0)?_c('div',{staticClass:"data-represent"},[_c('div',[_c('span',{staticClass:"d-info"}),_vm._v("  "),_c('strong',[_vm._v(_vm._s(_vm.form.items.meta.total))]),_c('small',[_vm._v(" Students")])]),_c('div',[_c('span',{staticClass:"d-primary"}),_vm._v("   "),_c('strong',[_vm._v(_vm._s(_vm.selectedStudents.length))]),_c('small',[_vm._v(" Selected Records")])]),_c('div',[_c('span',{staticClass:"d-success"}),_vm._v("   "),_c('strong',[_vm._v(_vm._s(_vm.totalSum.currency()))]),_c('small',[_vm._v(" Total Due")])])]):_vm._e(),_c('br'),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.form.items.data,"search":_vm.search,"options":_vm.pagination,"footer-props":_vm.footerProps,"server-items-length":_vm.form.items.meta.total,"loading":_vm.form.loading},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"header.select",fn:function(){return [(_vm.form.items.data.length > 0)?_c('v-checkbox',{staticClass:"ml-0",attrs:{"disabled":_vm.grade === 'all',"primary":"","input-value":_vm.form.items.data.length === _vm.selectedStudents.length &&
                _vm.selectedStudents.length !== 0,"indeterminate":_vm.selectedStudents.length !== 0 &&
                _vm.selectedStudents.length < _vm.form.items.data.length,"hide-details":""},on:{"click":function($event){$event.stopPropagation();return _vm.toggleAllStudent.apply(null, arguments)}}}):_c('span',[_vm._v("##")])]},proxy:true},{key:"item",fn:function({ index, item }){return [_c('tr',[_c('td',[(true)?_c('v-checkbox',{attrs:{"disabled":_vm.grade === 'all',"input-value":_vm.selectedStudents.includes(item),"primary":"","hide-details":""},on:{"click":function($event){$event.stopPropagation();return _vm.selectStudent(item)}}}):_vm._e()],1),_c('td',{staticClass:"text-xs-left"},[_vm._v(_vm._s(index + 1))]),_c('td',{staticClass:"text-xs-left"},[_vm._v(_vm._s(item.name))]),_c('td',{staticClass:"text-xs-left"},[_vm._v(_vm._s(item.enroll_code))]),_c('td',{staticClass:"text-xs-left"},[_c('strong',[_vm._v(_vm._s(item.due_amount.currency()))])]),_c('td',{staticClass:"text-right"},[_c('span',{staticClass:"mt-2"},[_vm._v(" "+_vm._s(item.guardian_name && item.guardian_name !== 'null' ? item.guardian_name : '-')+" "),(
                      item.guardian_mobile && item.guardian_mobile !== 'null'
                    )?_c('p',[_c('strong',[_vm._v(_vm._s(item.guardian_mobile))])]):_vm._e()])]),_c('td',{staticClass:"text-right"},[_c('v-btn',{attrs:{"outlined":"","xSmall":"","color":"warning"},on:{"click":function($event){return _vm.viewFeeHeadDetails(item,index)}}},[_c('strong',[_vm._v(" Details")])])],1)])]}},(_vm.form.items.data.length)?{key:"body.append",fn:function(){return [_c('tr',{staticStyle:{"background-color":"#eee"}},[_c('td',{staticClass:"text-xs-left",attrs:{"colspan":4}},[_c('strong',[_vm._v("Total:")])]),_c('td',{staticClass:"text-xs-left",attrs:{"width":"200","colspan":"2"}},[_c('strong',[_vm._v(_vm._s(_vm.totalSum.currency()))]),_vm._v(" "),_c('br'),_vm._v(" "+_vm._s(_vm.convertNumberToWords(_vm.totalSum))+" ")])])]},proxy:true}:null],null,true)})],1)],1)],1),_c('v-dialog',{attrs:{"scrollable":"","persistent":"","max-width":"1000px"},model:{value:(_vm.sendSMSModal),callback:function ($$v) {_vm.sendSMSModal=$$v},expression:"sendSMSModal"}},[_c('v-card',{staticClass:"primary--border",attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"primary white--text"},[_c('span',{staticClass:"title"},[_vm._v("Add/Update")])]),_c('v-card-text',[_c('br'),_c('alert-message',{attrs:{"type":"info","extraSmall":true,"title":`Send Bulk SMS Alert`},scopedSlots:_vm._u([{key:"message",fn:function(){return [_vm._v(" When using dynamic placeholders the below statistics can only be used for general idea and hence cannot be fully trusted. If you want to get the exact calculation please "),_c('a',{attrs:{"href":"#"}},[_c('u',[_vm._v(" Click here")])])]},proxy:true}])}),_c('div',{staticClass:"mt-2"},[_c('br'),_c('v-sms-input-field',{attrs:{"show-alert":false,"total-sms":_vm.selectedStudents.length,"default-placeholder":[
              {
                text: 'Guardian Name',
                placeholder: '{{guardian_name}}',
              },
              {
                text: 'Student Name',
                placeholder: '{{student_name}}',
              },

              {
                text: 'Guardian Mobile',
                placeholder: '{{guardian_mobile}}',
              },
              {
                text: 'Due Amount',
                placeholder: '{{due_amout}}',
              },
            ],"default-sms":``},on:{"message":_vm.message}})],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"warning","text":""},on:{"click":function($event){_vm.sendSMSModal = false}}},[_vm._v("Close")]),_c('v-btn',{attrs:{"disabled":_vm.sms.length < 1 || (_vm.my_sms && _vm.my_sms.balance < 1),"color":"success","loading":_vm.sendingSMS,"text":""},on:{"click":_vm.sendSMSNow}},[_vm._v("Send ")])],1)],1)],1),_c('v-dialog',{attrs:{"scrollable":"","persistent":"","max-width":"600px"},model:{value:(_vm.feeHeadDetailModal),callback:function ($$v) {_vm.feeHeadDetailModal=$$v},expression:"feeHeadDetailModal"}},[_c('v-card',{staticClass:"primary--border",attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"primary white--text"},[_c('span',{staticClass:"title"},[_vm._v("Fee Head Detail of ("+_vm._s(_vm.feeHeadDetailTitle)+")")])]),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.feeHeadDetailHeader,"hide-default-footer":"","items":_vm.feeHeadDetailsData},scopedSlots:_vm._u([{key:"item",fn:function({ index, item }){return [_c('tr',[_c('td',[_vm._v(_vm._s(index +1))]),_c('td',{staticClass:"text-xs-left"},[_vm._v(_vm._s(item.fee_head))]),_c('td',{staticClass:"text-xs-left"},[_vm._v(_vm._s(item.due_amount))])])]}}])})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"warning","text":""},on:{"click":function($event){_vm.feeHeadDetailModal = false}}},[_vm._v("Close")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }